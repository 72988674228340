import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaPinterestP, FaLinkedin, FaInstagram, FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok } from "react-icons/fa";
import ServiceData from "../../data/service/ServiceMain.json";
// import { slugify } from '../../utils';
import SubscribeForm from '../../component/subscribe/SubscribeForm';

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><Link to="https://www.facebook.com/wit.warrior/"><FaFacebookF /></Link></li>
                            {/*<li><Link to="https://twitter.com/"><FaTwitter /></Link></li>*/}
                            {/*<li><Link to="https://www.pinterest.com/"><FaPinterestP /></Link></li>*/}
                            <li><Link to="https://www.linkedin.com/company/97193955/"><FaLinkedin /></Link></li>
                            {/*<li><Link to="https://www.instagram.com/"><FaInstagram /></Link></li>*/}
                            {/*<li><Link to="https://vimeo.com/"><FaVimeoV /></Link></li>*/}
                            {/*<li><Link to="https://dribbble.com/"><FaDribbble /></Link></li>*/}
                            {/*<li><Link to="https://www.behance.net/"><FaBehance /></Link></li>*/}
                            {/*<li><Link to="https://www.tiktok.com/en/"><FaTiktok /></Link></li>*/}
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Stay in touch!</h2>
                                    <p>Stay up to date on new features.</p>
                                    {/* <form>
                                        <div className="input-group">
                                            <span className="mail-icon"><FaEnvelopeOpen /> </span>
                                            <input type="email" className="form-control" placeholder="Email address" />
                                            <button className="subscribe-btn" type="submit">Subscribe</button>
                                        </div>
                                    </form> */}
                                    <SubscribeForm />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Services</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        {/*<Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>*/}
                                                        <Link to="#">{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    {/*<div className="footer-widget">
                                        <h6 className="widget-title">Resources</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/case-study"}>Case Studies</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}>Portfolio</Link></li>
                                            </ul>
                                        </div>
                                    </div>*/}
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li>
                                                    {/*<Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link>*/}
                                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdaWzPd6aaGVeJvWrqUsE8Qr8uUY4Fhj08Nh6rkxes9-qq-DA/viewform?usp=sf_link" target='_blank' rel='noreferrer' style={{ display: 'inline-block', textDecoration: 'none' }}>
                                                        Contact Us
                                                    </a>
                                                </li>
                                                <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/chat-gpt"}>ChatGPT</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;