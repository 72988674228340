import { useState } from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { FaEnvelopeOpen } from "react-icons/fa";

const SubscribeForm = () => {
  const [afterSubmit, setAfterSubmit] = useState({ evt: false, link: "" });
  const initialValues = { email: "" };
  const validationSchema = object().shape({
    email: string()
      .required("Enter email address")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Enter valid email address"
      )
      .min(6, "Email should be at least 6 characters long")
      .max(50, "Email should not exceed 100 characters"),
  });

  const onSubmit = async (values, resetForm) => {
    const { email } = values;

    setAfterSubmit({
      evt: true,
      link: `https://docs.google.com/forms/d/e/1FAIpQLScZnRLmr0VKnFmVz941u1N4w_CleSJme1c7QjPvOLnCiWH0Vg/formResponse?entry.1784254578=${email}`,
    });
    resetForm();
  };

  return afterSubmit.evt ? (
    <>
      <iframe className="d-none" src={afterSubmit.link} title="form result" />
      <div className="color-white">
        Thanks for reaching out! We'll get back to you ASAP.
      </div>
    </>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
    >
      {({
        dirty,
        values,
        isValid,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form className="input-group">
          <span className="mail-icon">
            <FaEnvelopeOpen />{" "}
          </span>
          <input
            type="email"
            className="form-control"
            placeholder="Email address"
            onChange={(e) => setFieldValue("email", e.target.value)}
            value={values?.email}
            onBlur={handleBlur}
            required
          />
          <button
            disabled={!isValid || !dirty || isSubmitting}
            className="subscribe-btn"
            type="submit"
          >
            Subscribe
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SubscribeForm;
