import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />

{/* This site gave me a privacy policy, but it doesn't seem to work in jsx, need to find a way to make it work here: https://app.termly.io/dashboard/website/e8a133c6-3ad2-4734-a204-811aa4d73d0d/privacy-policy */}

            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h4 className="title">Introduction</h4>
                                        <p>Welcome to WitWarrior! We are committed to protecting your privacy and ensuring you have a positive experience on our website and with our services. This Privacy Policy outlines our practices regarding the collection, use, and protection of your data.
                                        </p>
                                        <h4 className="title">Data Collection</h4>
                                        <p>At WitWarrior, we collect the following types of data:</p>
                                        <ul>
                                            <li>Personal Information: This includes your name and email address.</li>
                                            <li>Usage Data: Information on how you interact with our services.</li>
                                            <li>Content Data: Any content you input into our system while using our services.</li>
                                            <li>Purpose of Data Use</li>
                                            <li>The data we collect is used to:
                                            </li>
                                        </ul>
                                        <p>The data we collect is used to:</p>
                                        <ul>
                                            <li>Personal Information: This includes your name and email address.</li>
                                            <li>Usage Data: Information on how you interact with our services.</li>
                                            <li>Content Data: Any content you input into our system while using our services.</li>
                                            <li>Purpose of Data Use</li>
                                        </ul>
                                        <h4 className="title">User Rights</h4>
                                        <p>As a user, you have the right to delete your account at any time. Deleting your account will result in the wiping of all data collected about you from our system.</p>
                                        <h4 className="title">Data Security</h4>
                                        <p>We employ state-of-the-art techniques to ensure the security and integrity of your data. Our commitment to data security means we continually update our security practices to prevent unauthorized access or disclosure.</p>
                                        <h4 className="title">Policy Changes</h4>
                                        <p>Any changes to our privacy policy will be communicated to you via email. We encourage you to review our privacy policy periodically.</p>
                                        <h4 className="title">Contact Information</h4>
                                        <p>For any privacy-related concerns or questions, please contact us at contact@buhgaming.com.</p>
                                        <h4 className="title">Geographical Considerations</h4>
                                        <p>Currently, WitWarrior services are offered exclusively to customers in the United States.</p>
                                        <h4 className="title">Age Restrictions</h4>
                                        <p>WitWarrior is intended for users who are 13 years of age and older. We do not knowingly collect personal information from children under the age of 13. In the event that we learn that we have collected personal information from a child under age 13 without parental consent, we will delete that information as quickly as possible.</p>
                                        <h4 className="title">Cookies and Tracking Technology</h4>
                                        <p>WitWarrior does not use cookies or similar tracking technologies in our services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<CtaLayoutOne />*/}
                <FooterOne parentClass="" />
            </main>

        </>
    )
}

export default PrivacyPolicy;