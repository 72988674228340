import React, { useEffect, useState } from 'react';
import Logo from '../../elements/logo/Logo';
import Nav from '../../common/header/Nav';
import OffcanvasMenu from './OffcanvasMenu';
import StickyHeader from './StickyHeader';
// import SwitcherHeader from '../../elements/switcher/SwitcherHeader';
import MobileMenu from './MobileMenu';


const HeaderOne = () => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isFullWidth, setFullWidth] = useState(true);

    const OffcanvasHandleClose = () => setShowOffcanvas(false);
    const OffcanvasHandleShow = () => setShowOffcanvas(true);

    const sticky = StickyHeader(100);

    const MobileMenuHandler = () => {
        const mobileMenuPopup = document.querySelector('.mobilemenu-popup');
        const body = document.querySelector('body');

        if (!isFullWidth) {
            mobileMenuPopup.classList.toggle("show");
            body.classList.toggle("mobilemenu-show");
            var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');

            for (var i in elements) {
                if (elements.hasOwnProperty(i)) {
                    elements[i].onclick = function() {
                        this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                        this.classList.toggle("open");
                    };
                }
            }
        } else {
            body.classList.remove("mobilemenu-show");
            mobileMenuPopup.classList.remove("show");
        }
    };
// change the mobile navbar Issue and resize issue
    useEffect(() => {
        const handleResize = () => {
            setFullWidth(window.innerWidth > 1024);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
        const mobileMenuPopup = document.querySelector('.mobilemenu-popup');
        if (isFullWidth) {
            const mobileMenuPopup = document.querySelector('.mobilemenu-popup');
            mobileMenuPopup.classList.remove("show");
            const body = document.querySelector('body');
            body.classList.remove("mobilemenu-show");
            mobileMenuPopup.style.display = 'none'
        }else{
            mobileMenuPopup.style.display = 'block'
        }
    },[isFullWidth])

    return (
        <>
            <header className="header axil-header header-style-1">
                <div className={`axil-mainmenu ${sticky ? "axil-sticky" : ""}`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                                <Logo limage="/images/WitWarriorLogo.png"
                                dimage="/images/WitWarriorLogo.png"
                                simage="/images/WitWarriorLogo.png"
                                logo_title={'Wit Warrior'}
                                />
                            </div>
                            <div className="header-main-nav">
                                <Nav />
                            </div>
                            <div className="header-action">
                                <ul className="list-unstyled">
                                    {/* <li className="sidemenu-btn d-lg-block d-none">
                                        <button className="btn-wrap" onClick={OffcanvasHandleShow}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li> */}
                                    <li className="mobile-menu-btn sidemenu-btn d-lg-none d-block">
                                        <button className="btn-wrap" onClick={MobileMenuHandler}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li>
                                    {/* <li className="my_switcher d-block d-lg-none">
                                        <SwitcherHeader />
                                    </li> */}
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </header>
            <OffcanvasMenu offcanvasShow={showOffcanvas} offcanvasHide={OffcanvasHandleClose} />
            <MobileMenu MobileHandler={MobileMenuHandler}/>
        </>
    )
}

export default HeaderOne;