import { useState } from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";

const ContactForm = () => {
  const [afterSubmit, setAfterSubmit] = useState({ evt: false, link: "" });
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    help: "",
  };
  const validationSchema = object().shape({
    name: string()
      .trim()
      .required("Enter name")
      .matches(/^[A-Za-z\s]+$/, "Special characters and digits are not allowed")
      .min(2, "Name should be at least 2 characters long")
      .max(50, "Name should not exceed 50 characters"),
    email: string()
      .required("Enter email address")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Enter valid email address"
      )
      .min(6, "Email should be at least 6 characters long")
      .max(50, "Email should not exceed 100 characters"),
    phoneNumber: string(),
    help: string().required("This is a required field"),
  });

  const onSubmit = async (values, resetForm) => {
    const { name, email, phoneNumber, help } = values;

    setAfterSubmit({
      evt: true,
      link: `https://docs.google.com/forms/d/e/1FAIpQLSdaWzPd6aaGVeJvWrqUsE8Qr8uUY4Fhj08Nh6rkxes9-qq-DA/formResponse?entry.1884265043=${name}&entry.1072614206=${email}&entry.1336118368=${phoneNumber}&entry.513669972=${help}`,
    });
    resetForm();
  };

  return afterSubmit.evt ? (
    <>
      <iframe className="d-none" src={afterSubmit.link} title="form result" />
      <div className="color-white">
        Thanks for reaching out! We'll get back to you ASAP.
      </div>
    </>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm);
      }}
    >
      {({
        errors,
        touched,
        dirty,
        values,
        isValid,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form className="row g-3">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name*
            </label>
            <input
              id="name"
              type="text"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("name", e.target.value)}
              value={values?.name}
              onBlur={handleBlur}
            />
            {errors?.name && touched?.name && (
              <div className="d-block invalid-feedback">{errors.name}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address*
            </label>
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("email", e.target.value)}
              value={values?.email}
              onBlur={handleBlur}
            />
            {errors?.email && touched?.email && (
              <div className="d-block invalid-feedback">{errors.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
              value={values?.phoneNumber}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="help" className="form-label">
              How can we help you?*
            </label>
            <input
              id="help"
              type="text"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("help", e.target.value)}
              value={values?.help}
              onBlur={handleBlur}
            />
            {errors?.help && touched?.help && (
              <div className="d-block invalid-feedback">{errors.help}</div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <button
              disabled={!isValid || !dirty || isSubmitting}
              className="btn btn-secondary"
              type="submit"
            >
              Submit
            </button>
            <button className="btn btn-dark" type="reset">
              Clear form
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
