import { useState } from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

import { Configuration, OpenAIApi } from 'openai';
import FormSection from '../component/chatgpt/FormSection';
import AnswerSection from '../component/chatgpt/AnswerSection';


const ChatGpt = () => {
    const configuration = new Configuration({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    });

    const openai = new OpenAIApi(configuration);

    const [storedValues, setStoredValues] = useState([]);

    const generateResponse = async (newQuestion, setNewQuestion) => {
        let options = {
            model: 'text-davinci-003',
            temperature: 0,
            max_tokens: 100,
            top_p: 1,
            frequency_penalty: 0.0,
            presence_penalty: 0.0,
            stop: ['/'],
        };

        let completeOptions = {
            ...options,
            prompt: newQuestion,
        };

        const response = await openai.createCompletion(completeOptions);

        console.log(response.data.choices[0].text);

        if (response.data.choices) {
            setStoredValues([
                {
                    question: newQuestion,
                    answer: response.data.choices[0].text,
                },
                ...storedValues,
            ]);
            setNewQuestion('');
        }

    };

    return (
        <>
            <SEO title="ChatGPT" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="ChatGPT"
                page="ChatGPT"
                />
                <div className="section-padding chatgpt-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="header-section">
                                <h1>ChatGPT Testing 🤖</h1>
                                <p>
                                    I am an automated question and answer system, designed to assist you
                                    in finding relevant information. You are welcome to ask me any queries
                                    you may have, and I will do my utmost to offer you a reliable
                                    response. Kindly keep in mind that I am a machine and operate solely
                                    based on programmed algorithms.
                                </p>
                            </div>
                            <FormSection generateResponse={generateResponse}/>
                            <AnswerSection storedValues={storedValues}/>
                        </div>
                    </div>
                </div>

                {/*<CtaLayoutOne />*/}
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default ChatGpt;