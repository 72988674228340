import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({ limage, dimage, simage, logo_title }) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <div>
                <img className="light-version-logo" src={process.env.PUBLIC_URL + limage} alt="logo" />
                <img className="dark-version-logo" src={process.env.PUBLIC_URL + dimage} alt="logo" />
                <img className="sticky-logo" src={process.env.PUBLIC_URL + simage} alt="logo" />
                <span className='logo_title'>{logo_title}</span>
            </div>
        </Link>
    )
}


export default Logo;