import { useState } from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";

const BetaSignUpForm = () => {
  const [afterSubmit, setAfterSubmit] = useState({ evt: false, link: "" });
  const initialValues = {
    name: "",
    email: "",
    org: "",
    job: "",
    purpose: "",
  };
  const validationSchema = object().shape({
    name: string()
      .trim()
      .required("Enter name")
      .matches(/^[A-Za-z\s]+$/, "Special characters and digits are not allowed")
      .min(2, "Name should be at least 2 characters long")
      .max(50, "Name should not exceed 50 characters"),
    email: string()
      .required("Enter email address")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Enter valid email address"
      )
      .min(6, "Email should be at least 6 characters long")
      .max(50, "Email should not exceed 100 characters"),
    org: string().required("Enter organization"),
    job: string().required("Enter job title"),
    purpose: string()
      .required("This is a required field")
      .notOneOf(["Choose"], "This is a required field"),
  });

  const onSubmit = async (values, resetForm) => {
    const { name, email, org, job, purpose } = values;

    setAfterSubmit({
      evt: true,
      link: `https://docs.google.com/forms/d/e/1FAIpQLSeKNsbfln9eAdX7yr_FlsrtVIJXopWvXf4yNjHJye-xG6Kn9Q/formResponse?entry.1884265043=${name}&entry.1371619601=${email}&entry.257313050=${org}&entry.2002820847=${job}&entry.1217915649=${purpose
        .split(" ")
        .join("+")}`,
    });
    resetForm();
  };

  return afterSubmit.evt ? (
    <>
      <iframe className="d-none" src={afterSubmit.link} title="form result" />
      <div className="color-white">
        Thanks for reaching out! We'll get back to you ASAP.
      </div>
    </>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm);
      }}
    >
      {({
        errors,
        touched,
        dirty,
        values,
        isValid,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form className="row g-3">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name <span className="required-start">*</span>
            </label>
            <input
              id="name"
              type="text"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("name", e.target.value)}
              value={values?.name}
              onBlur={handleBlur}
            />
            {errors?.name && touched?.name && (
              <div className="d-block invalid-feedback">{errors.name}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address <span className="required-start">*</span>
            </label>
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("email", e.target.value)}
              value={values?.email}
              onBlur={handleBlur}
            />
            {errors?.email && touched?.email && (
              <div className="d-block invalid-feedback">{errors.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="org" className="form-label">
              Organization <span className="required-start">*</span>
            </label>
            <input
              type="text"
              id="org"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("org", e.target.value)}
              value={values?.org}
              onBlur={handleBlur}
            />
            {errors?.org && touched?.org && (
              <div className="d-block invalid-feedback">{errors.org}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="job" className="form-label">
              Job Title <span className="required-start">*</span>
            </label>
            <input
              id="job"
              type="text"
              className="form-control"
              placeholder="Your answer"
              aria-label="Your answer"
              onChange={(e) => setFieldValue("job", e.target.value)}
              value={values?.job}
              onBlur={handleBlur}
            />
            {errors?.job && touched?.job && (
              <div className="d-block invalid-feedback">{errors.job}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="purpose" className="form-label">
              I would like to use this tool for: <span className="required-start">*</span>
            </label>
            <select
              className="form-select"
              id="purpose"
              aria-label="Default select example"
              onChange={(e) => setFieldValue("purpose", e.target.value)}
              value={values?.purpose}
              onBlur={handleBlur}
            >
              <option>Choose</option>
              <option>Marketing Content</option>
              <option>Writing Scripts</option>
              <option>Influencer Content</option>
              <option>Make my writing more humorous in general</option>
              <option>Other</option>
            </select>
            {errors?.purpose && touched?.purpose && (
              <div className="d-block invalid-feedback">{errors.purpose}</div>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <button
              disabled={!isValid || !dirty || isSubmitting}
              className="btn btn-secondary"
              type="submit"
            >
              Submit
            </button>
            <button className="btn btn-dark" type="reset">
              Clear form
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BetaSignUpForm;
