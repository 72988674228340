import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h4 className="title">Acceptance of Terms</h4>
                                        <p>Users are required to agree to the terms and conditions before using the service. This can be done through explicit consent, such as clicking a checkbox or button during the signup process.</p>
                                        <h4 className="title">User Conduct</h4>
                                        <p>The rules and expectations for how users should behave while using the service. This may include prohibitions on illegal activities, harassment, or any other behavior that violates the terms.</p>
                                        <h4 className="title">Intellectual Property</h4>
                                        <p>Information about the ownership and use of intellectual property, such as copyrights, trademarks, and patents, associated with the service.</p>
                                        <h4 className="title">Privacy Policy</h4>
                                        <p>Details about how user data is collected, stored, and used. This is particularly important in the context of privacy regulations.</p>
                                        <h4 className="title">Liability and Disclaimers</h4>
                                        <p>Information about the limitations of the service provider's liability and any disclaimers regarding the use of the service.</p>
                                        <h4 className="title">Termination of Services</h4>
                                        <p>Conditions under which the service provider can terminate a user's access to the service, and the consequences of such termination.</p>
                                        <h4 className="title">Modifications to Terms</h4>
                                        <p>The right of the service provider to change the terms and conditions and how users will be informed of such changes.</p>
                                        <h4 className="title">Governing Law</h4>
                                        <p>The jurisdiction that will govern the agreement and any disputes that may arise.</p>
                                        <h4 className="title">Payment Terms</h4>
                                        <p>If applicable, details about fees, billing, and payment terms.</p>
                                        <h4 className="title">User Responsibilities</h4>
                                        <p>Outlining the responsibilities of the user in terms of account security, accurate information provision, and adherence to the terms.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<CtaLayoutOne />*/}
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;